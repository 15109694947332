import React from "react";
import {NavLink} from "react-router-dom";
function Nav(){
    return(
< div className="nav">
<div className="box1">
<NavLink className="actives">Portfo<span>lio.</span></NavLink>
</div>
<div className="box2">
<NavLink className="active-class" to="/h">Home</NavLink>
<NavLink className="active-class" to="/about">About</NavLink>
<NavLink className="active-class" to="/h">Services</NavLink>
<NavLink className="active-class" to="/">Team</NavLink>
<NavLink className="active-class" to="/">Skills</NavLink>
<NavLink className="active-class" to="/">Contact</NavLink>
</div>
</div>
    );
}
export default Nav;