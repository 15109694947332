import React from "react";
import img1 from "./image/r.jpg";
import img2 from "./image/rahul.jpg";
function Home(props){

    return(
    <>
  
           <img src={img1} alt=""/>
           <div className="image-content">
              <div className="photo"><img src={img2} alt="" /></div>
                 <div className="text">
                   <p>I'm Rahul and I'm  a <span>Webdeveloper</span></p>
                   <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, quos doloremque. 
                    Nihil magnam sunt molestiae quia illum amet rem facilis expedita repellat optio obcaecati,
                     vitae accusantium ducimus fuga dignissimos, laborum corporis doloribus deserunt adipisci non 
                     voluptates. Ex dolores ab molestiae error maxime nesciunt amet aliquam corporis sequi nostrum 
                     voluptates voluptatibus aut voluptate, itaque asperiores cum tempora, doloremque ratione, voluptatum
                      iusto.</p>
                 </div>
          </div> 
    </>
  );
}
export default Home;