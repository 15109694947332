//import logo from './logo.svg';
import './App.css';
import {Routes,Route} from "react-router-dom";
import About from "./About";
import Heading from "./Heading";
import Home from "./Home";
import List from "./List";
import Error from "./Error";
//import Menu from "./Nav";
import Nav from "./Nav";
function App() {
 
  return (
    <>
    <Nav name="sahani"/>

    <Routes>
    <Route exact path="/h" element={<Home  name=" " />}/>
    <Route path="/about" element={<About name="rahul"/>}/>
    <Route path="/" element={<List/>}/>
    <Route path="/" element={<Heading/>}/>
    <Route path="*" element={ <Error/>}/>
    </Routes>
    </>
  );
}

export default App;


