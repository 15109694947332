/*var React=require('react');
var ReactDOM=require('react-dom');*/
import React from 'react';
import ReactDOM from 'react-dom';
import App from  "./App";
import {BrowserRouter} from "react-router-dom";
import "./App.css";

//import Fun from './Fun';
ReactDOM.render(
     <BrowserRouter>
     <App/>
     </BrowserRouter>
,document.getElementById("app")
);





