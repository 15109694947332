import React from "react";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
function About(props){
    const handalForm = (e)=>{
        console.log(e.target.value.target.name);
       }
    return(<div>
<form action="" className="form-style">
        <label htmlFor="">User Name</label>
        <input type="text" name="username" onChange={handalForm} /><br />
        <label htmlFor="">Password</label>
        <input type="text" name="password" onChange={handalForm} /><br />
        <input type="submit" value="Submit" />
       </form>
    </div>);
}
export default About;